import React from 'react'
import { graphql, Link } from "gatsby"
import tw, { styled } from 'twin.macro'

import Layout from "../components/Layout"
import Section from '../components/Section'

const InnerSection = styled.article`
  ${tw`
    max-w-screen-md 
    w-full mx-auto my-10
    text-lg
  `}
`

const UnderlinedLink = styled(Link)`
    ${tw`
        underline hover:no-underline
    `}
`

const Meta = styled.div`
  ${tw`
    text-base
  `}
`

// This takes on the size of an h2 as per the custom global styles but every page needs to have an h1 for SEO optimization
const Title = styled.h1`
  ${tw`
    mt-5 mb-20 font-bold w-full

    text-5xl sm:text-6xl
    leading-tight sm:leading-tight
  `}

  @media (max-width: 768px) {
    ${tw`
      text-5xl
    `}
  }
`

const Body = styled.div`
  ${tw`
    order-2 lg:order-1
  `}

  & > *:first-child {
    ${tw`
      pt-0 
      mt-0
    `}
  }

  h3, h2 {
    ${tw`
      pt-12 pb-4
    `}
  }

  h6 {
    color: darkorange;
  }

  p {
    ${tw`
      py-4 px-0
    `}
  }

  a {
    ${tw`
      no-underline hover:underline
    `}

    color: darkorange;
  }

  ol, ul {
    ${tw`
      py-4 pl-7
    `}
  }

  ol {
    ${tw`
      list-decimal
    `}
  }

  ul {
    ${tw`
      list-disc
    `}
  }

  img {
    ${tw`
      object-contain 
      max-w-full
    `}
  }

  table {
    ${tw`
      border-collapse
    `}
  }

  th, td {
    ${tw`
      py-2 px-6
      border border-gray-300
    `}
  }

  blockquote {
    ${tw`
      mt-4
      pl-12
      border-l-2 border-gray-300
    `}
  }

  code {
    font-style: oblique;
  }

  iframe {
    ${tw`
      py-4
      w-full
    `}
  }
`

const OtherPosts = styled.div`
  ${tw`
    grid gap-6
    grid-cols-1 md:grid-cols-2
  `}
`

const OpArticle = styled.article`
  ${tw`
    px-6 py-10
    rounded-xl shadow-2xl
    border-t-8
    flex flex-col
    h-full
  `}

  border-color: darkorange;

  > p:first-of-type {
    ${tw`
      mb-4
    `}
  }
`

const OpHeaderLink = styled(Link)`
  ${tw`
    font-semibold
    flex-grow
    hover:underline
  `}

  > h5 {
    ${tw`
      mb-4
    `}
  }
`

const OpMeta = styled.div`
  ${tw`
    text-sm
  `}
`

const BlogPost = ({
  data: {
    markdownRemark: {
      html,
      excerpt,
      frontmatter: {
        seo,
        title,
        date,
        categories
      }
    },
    allMarkdownRemark: { edges: otherPosts }
  }
}) => {
  function getUrl(category) {
    let retUrl = "/blog"
    if (category)
      retUrl += "/" + category.replace(/\s+/g, '-').toLowerCase();

    return retUrl;
  }

  return (
    <Layout title={seo.title} description={seo.description || excerpt}>
      <Section>
        <InnerSection>
          <Meta>
            {categories.map((category, idx) => (
              <>
                <UnderlinedLink to={getUrl(category)}>{category}</UnderlinedLink>{idx < categories.length - 1 ? ", " : " "}
              </>
            ))}
            &#9679; <span>{date}</span>
          </Meta>
          <Title>{title || seo?.title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: html }} />
        </InnerSection>
      </Section>
      <Section>
        <OtherPosts>
          {otherPosts &&
            otherPosts.map(({ node: { excerpt, fields, frontmatter: { title, categories, date } } }, postIdx) => (
              <OpArticle>
                <OpHeaderLink to={fields.slug}>
                  <h5>{title}</h5>
                </OpHeaderLink>
                <p>{excerpt}</p>
                <OpMeta>
                  {categories.map((category, idx) => (
                    <>
                      <UnderlinedLink to={getUrl(category)}>{category}</UnderlinedLink>{idx < categories.length - 1 ? ", " : " "}
                    </>
                  ))}
                  &#9679; <span>{date}</span>
                </OpMeta>
              </OpArticle>
            ))}
        </OtherPosts>
      </Section>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query blogPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        seo {
          title 
          description
        }
        title
        date(formatString: "MMM Do, YYYY")
        categories
      }
    }
    allMarkdownRemark(
        filter: {
          frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } },
          id: { ne: $id },
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 2
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM Do, YYYY")
            categories
          }
          excerpt(pruneLength: 130)
        }
      }
    }
  }
`